.about__container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.about__img {
  width: 350px;
  box-shadow: 10px 5px 5px black;
  border-radius: 1.5rem;
  justify-self: center;
}

.about__info {
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}

.about__box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 10px 5px 5px black;
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}

.about__achievement {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 10px 5px 5px black;
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}

.about__icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}

.about__page__title {
  padding-top: 0.5rem;
}

.about__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.about__subtitle {
  font-size: var(--smaller-font-size);
}

.about__introduction {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 10px 5px 5px black;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  font-size: var(--normal-font-size);
  color: var(--text-color);
  padding: 1rem 1.25rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.about__description {
  font-size: 1.3rem;
  color: var(--title-color-dark);
  margin-bottom: 2rem;
}

/* ============ Breakpoints ============ */
/* Large Devices */
@media screen and (max-width: 992px) {
  .about__container {
    flex-basis: auto;
    row-gap: 2.5rem;
  }

  .about__img {
    width: 220px;
  }

  .about__box {
    padding: 0.75rem 0.5rem;
  }

  .about__info {
    justify-content: center;
  }

  .about__introduction {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
}

/* Medium Devices */
@media screen and (max-width: 768px) {
  .about__container {
    flex-wrap: nowrap;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .about__info {
    flex-wrap: wrap;
  }
  .about__description {
    font-size: var(--h3-font-size);
    padding: 1rem 2rem 1rem 2rem;
  }
}

@media screen and (max-width: 576px) {
  .about__container {
    flex-wrap: wrap;
  }
  .about__img {
    margin-left: auto;
    margin-right: auto;
  }
  .about__introduction {
    padding: 1rem;
    font-size: ();
  }
  .about__description {
    font-size: var(--normal-font-size);
    padding: 1rem 2rem 1rem 2rem;
  }
}
/* Small Devices */
@media screen and (max-width: 350px) {
  .about__container {
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .about__box {
    margin-left: auto;
    margin-right: auto;
  }
  .about__img {
    margin-left: auto;
    margin-right: auto;
    width: 190px;
  }
  .about__introduction {
    justify-content: center;
    padding: 0 0rem;
    margin-top: 0rem;
  }

  .about__description {
    padding: 1rem 2rem 1rem 2rem;
    font-size: var(--small-font-size);
    color: var(--title-color);
    margin-bottom: 0;
  }
  .about__section {
    align-self: center;
  }
}
