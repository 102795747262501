.tech__skills__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 1.5rem;
}

.skills__container {
  grid-template-columns: repeat(2, 350px);
  column-gap: 3rem;
  justify-content: center;
}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 1rem;
  border-radius: 1.25rem;
}

.skills__title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-0-75);
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.flex-grow {
  flex-grow: 1;
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: green;
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  background-color: #dcdcdc;
  padding: 2px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.skills__level::before {
  content: attr(data-skill);
  background-color: #000000;
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-radius: inherit;
  animation: load 2s 0s;
  -webkit-animation: load 2s 0s;
  -moz-animation: load 2s 0s;
  -o-animation: load 2s 0s;
}

.skills__level.semilearning::before {
  width: calc(10% - 10px);
}

.skills__level.learning::before {
  width: calc(20% - 10px);
}

.skills__level.basiclearning::before {
  width: calc(30% - 10px);
}

.skills__level.basic::before {
  width: calc(40% - 10px);
}
.skills__level.basicintermediate::before {
  width: calc(50% - 10px);
}

.skills__level.intermediate::before {
  width: calc(60% - 10px);
}

.skills__level.intermediateadvanced::before {
  width: calc(70% - 10px);
}

.skills__level.advanced::before {
  width: calc(80% - 10px);
}

.skills__level.advancedexpert::before {
  width: calc(90% - 10px);
}

.skills__level.expert::before {
  width: calc(100% - 10px);
}

@keyframes load {
  from {
    width: 0%;
  }
}

@-webkit-keyframes load {
  from {
    width: 0%;
  }
}

@-moz-keyframes load {
  from {
    width: 0%;
  }
}

@-o-keyframes load {
  from {
    width: 0%;
  }
}

/* ============ Breakpoints ============ */
/* Large Devices */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-rows: max-content;
    row-gap: 2rem;
  }
}

/* Medium Devices */
@media screen and (max-width: 768px) {
  .skills__container {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  .skills__content {
    padding: 1.5rem;
  }
}
