.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden;
}

.home__content {
  display: flex;
  flex-direction: row;
  padding-bottom: 3rem;
  column-gap: 2rem;
  align-items: center;
}

.social__img__container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.home__social {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__languages {
  align-items: center;
}

.home__languages__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.home__languages-icon {
  font-size: 5rem;
  color: var(--title-color);
}

.language__info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.language__name {
  font-size: var(--normal-font-size);
  color: var(--title-color);
  font-weight: bold;
  align-items: center;
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__title {
  font-size: var(--big-font-size);
  align-items: center;
}

.em {
  width: 35px;
  height: 35px;
  margin-left: 0.4rem;
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  padding-right: 5.4rem;
  font-weight: var(--font-normal);
  justify-content: center;
}

.home__subtitle::before,
.home__subtitle::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  top: 1rem;
}

.home__subtitle::before {
  left: 0;
}

.home__subtitle::after {
  right: 0;
}

.home__description {
  max-width: 400px;
  text-align: center;
}

.home__data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  flex-grow: 1;
}

.home__img {
  background: url(../../assets//Profile_Picture.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  border-radius: 30% 60% 70% 70%/50% 60% 30% 60%;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

.home__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* ========== Animations ========== */
@keyframes profile__animate {
  0%,
  100% {
    border-radius: 30% 60% 70% 70%/50% 60% 30% 60%;
  }

  50% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

/* ============ Breakpoints ============ */
/* Large Devices */
@media screen and (max-width: 992px) {
  .home__content {
    flex-direction: column;
    column-gap: 1.25rem;
  }

  .em {
    width: 26px;
    height: 26px;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__subtitle::after {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    padding-bottom: 0.5rem;
  }

  .home__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
  }
  .home__languages__list {
    gap: 1rem;
    justify-content: center;
  }

  .home__languages-icon {
    font-size: 4rem;
  }
  .language__name {
    font-size: calc(var(--normal-font-size) * 0.9);
  }
}

/* Medium Devices */
@media screen and (max-width: 768px) {
  .home__data {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home__content {
    padding-top: 3.5rem;
  }

  .home__img {
    box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
    width: 200px;
    height: 200px;
  }
  .home__description {
    border-bottom: 1px solid;
    border-bottom-color: var(--subtitle-color);
  }

  .home__languages-icon {
    font-size: 3.5rem;
  }
  .language__name {
    font-size: calc(var(--normal-font-size) * 0.8);
  }
}

/* Small Devices */
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
    box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
  }

  .em {
    width: 26px;
    height: 26px;
  }

  .home__languages__list {
    flex-direction: column; /* Stack languages vertically on smaller screens */
    gap: 1rem;
  }

  .home__languages-icon {
    font-size: 3rem;
  }
  .language__name {
    font-size: calc(var(--normal-font-size) * 0.7);
  }
}
